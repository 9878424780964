import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { PROD_API_URL } from "../../const";
import "./Contact.css";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const theme = useTheme();

  useEffect(() => {
    const noErrors = Object.values(errors).every((error) => !error);
    const requiredFieldsFilled = ["name", "email"].every(
      (field) => formData[field] !== ""
    );

    setIsFormValid(noErrors && requiredFieldsFilled);
  }, [errors, formData]);

  // Regular expressions and validation functions
  const regex = {
    name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    phone: /^\d{0,14}$/,
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!value) {
          error = "El nombre es requerido.";
        } else if (!regex.name.test(value)) {
          error = "El nombre solo puede contener hasta 40 letras y espacios.";
        }
        break;
      case "email":
        if (!value) {
          error = "El correo electrónico es requerido.";
        } else if (!regex.email.test(value)) {
          error = "Ingrese un correo electrónico válido.";
        }
        break;
      case "phone":
        if (value && !regex.phone.test(value)) {
          error = "El teléfono puede tener hasta 14 dígitos.";
        }
        break;
      case "company":
        // No validation for company
        break;
      case "message":
        // No validation for message
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateForm = () => {
    let isValid = true;
    const requiredFields = ["name", "email"];

    requiredFields.forEach((field) => {
      validateField(field, formData[field]);
      if (formData[field] === "" || errors[field]) {
        isValid = false;
      }
    });

    if (formData.phone) {
      validateField("phone", formData.phone);
      if (errors.phone) {
        isValid = false;
      }
    }

    return isValid;
  };

  // Event handlers
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true); // Disable the button during submission
      setIsFormValid(false);
      try {
        const response = await axios.post(`${PROD_API_URL}/contact`, formData);
        if (response.status === 200) {
          setFormData({
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          });
          setSnackbar({
            open: true,
            message: "¡Mensaje enviado exitosamente!",
            severity: "success",
          });
        }
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error al enviar el mensaje. Por favor, intente nuevamente.",
          severity: "error",
        });
      } finally {
        setIsSubmitting(false); // Re-enable the button after submission
        setIsFormValid(true);
      }
    } else {
      // Handle form invalid case if needed
    }
  };

  // JSX
  return (
    <>
      <Container
        id="contact"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 2, sm: 4 },
        }}
      >
        <Typography component="h2" variant="h4" sx={{ color: "text.primary" }}>
          Contáctanos
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          ¿Te interesa Vita? <br />
          <br />
          Contáctanos para que juntos mejoremos la experiencia de la salud.
          Respondemos rápido.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 4,
            }}
          >
            <Box className="form-col" sx={{ flex: 1, minWidth: "250px" }}>
              <FormControl fullWidth error={Boolean(errors.name)}>
                <TextField
                  required
                  id="name"
                  name="name"
                  placeholder="Ian Fry"
                  label="Nombre"
                  value={formData.name}
                  onChange={handleChange}
                  error={Boolean(errors.name)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText>{errors.name}</FormHelperText>
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(errors.phone)}
                sx={{ mt: 2 }}
              >
                <TextField
                  id="phone"
                  name="phone"
                  placeholder="9988776655"
                  label="Teléfono"
                  value={formData.phone}
                  onChange={handleChange}
                  error={Boolean(errors.phone)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText>{errors.phone}</FormHelperText>
              </FormControl>
            </Box>

            <Box sx={{ flex: 1, minWidth: "250px" }} className="form-col">
              <FormControl fullWidth error={Boolean(errors.email)}>
                <TextField
                  id="email"
                  name="email"
                  placeholder="ian@fry.com"
                  label="Correo electrónico"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText>{errors.email}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(errors.company)}
                sx={{ mt: 2 }}
              >
                <TextField
                  id="company"
                  name="company"
                  placeholder="Vita"
                  label="Empresa"
                  value={formData.company}
                  onChange={handleChange}
                  error={Boolean(errors.company)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText>{errors.company}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
          {/* Message Field */}{" "}
          <Box sx={{ flex: 1, minWidth: "250px", mt: 1.5 }}>
            {" "}
            <FormControl
              fullWidth
              error={Boolean(errors.message)}
              sx={{ mt: 2 }}
            >
              {" "}
              <TextField
                id="message"
                name="message"
                placeholder="Me interesa Vita"
                label="Mensaje"
                value={formData.message}
                onChange={handleChange}
                error={Boolean(errors.message)}
                slotProps={{ inputLabel: { shrink: true } }}
              />{" "}
              <FormHelperText>{errors.message}</FormHelperText>{" "}
            </FormControl>{" "}
          </Box>
          {/* Submit Button */}
          <Button
            type="submit"
            className="send-contact-btn"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                isFormValid && !isSubmitting
                  ? "var(--primary-color)"
                  : "grey.500",
              color: "white",
              alignSelf: "flex-end",
              mt: 3,
              "&:disabled": {
                backgroundColor: "grey.500",
              },
            }}
            disabled={!isFormValid || isSubmitting} // Disable when form is invalid or submitting
          >
            Enviar
            <i className="bi bi-arrow-right send-contact-icon"></i>
          </Button>
        </Box>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
