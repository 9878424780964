import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import LogoCollection from "./components/LogoCollection";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import getMPTheme from "./theme/getMPTheme";
import TemplateFrame from "./TemplateFrame";
import Contact from "./components/Contact";
import { PROD_API_URL } from "../const";
import NumbersSection from "./components/Numbers";
import axios from "axios";
import VitaBenefits from "./components/Benefits";

export default function MarketingPage() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const fetchData = async () => {
    /* const heartbeat = await axios.get(`${PROD_API_URL}/`); */
  };

  React.useEffect(() => {
    fetchData();
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
    >
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <div 
          className="responsive-container"
          style={{ 
            width: '100%', 
            overflowX: 'hidden', 
            maxWidth: '100%', 
            position: 'relative' 
          }}
        >
          <AppAppBar />
          <Hero />
          <div>
            <LogoCollection />
            <Divider />
            <VitaBenefits />
            <Divider />
            <NumbersSection />
            <Divider />
            <FAQ />
            <Divider />
            <Contact />
            <Divider />
            <Footer />
          </div>
        </div>
      </ThemeProvider>
    </TemplateFrame>
  );
}