import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  InputLabel,
  TextField,
  Typography,
  Stack,
  Button,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { PROD_API_URL } from "../../const";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./Hero.css";
import { happyLog } from "./coolLog";

gsap.registerPlugin(ScrollTrigger);

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundSize: "cover",
  opacity: 0, // Start hidden for animation
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...(theme.palette.mode === "dark" && {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  const phrases = [
    "Simplificamos",
    "Optimizamos",
    "Aceleramos",
    "Mejoramos",
    "Facilitamos",
  ];
  const wordRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  setTimeout(() => {
    happyLog();
  }, 1000);

  useEffect(() => {
    wordRefs.current = wordRefs.current.slice(0, phrases.length);
  }, [phrases.length]);

  useEffect(() => {
    const rotateWords = () => {
      const currentWord = wordRefs.current[currentIndex];
      const nextIndex = (currentIndex + 1) % phrases.length;
      const nextWord = wordRefs.current[nextIndex];

      const tl = gsap.timeline({
        defaults: {
          ease: "power3.inOut",
        },
      });

      tl.to(currentWord, {
        rotateX: 90,
        y: "-50%",
        opacity: 0,
        duration: 0.6,
        scale: 0.6,
        ease: "power2.in",
        onComplete: () => {
          // Reset the current word to be ready for the next cycle
          gsap.set(currentWord, {
            rotateX: 0,
            y: "50%",
            opacity: 0,
            scale: 1,
          });
        },
      });

      tl.fromTo(
        nextWord,
        {
          rotateX: -90,
          y: "80px",
          opacity: 0,
          scale: 0.6,
        },
        {
          rotateX: 0,
          y: "-15%",
          scale: 1,
          opacity: 1,
          duration: 0.6,
          ease: "power2.out",
        },
        "-=0.3"
      );

      tl.add(() => {
        setCurrentIndex(nextIndex);
      });
    };

    const interval = setInterval(rotateWords, 3000);
    return () => clearInterval(interval);
  }, [currentIndex, phrases.length]);

  const theme = useTheme();

  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const formRef = useRef(null);
  const heroDemoRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // GSAP ENTRY Animations
  useEffect(() => {
    const appbar = document.getElementById("appbar");
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({ defaults: { ease: "power3.out" } });
      tl.delay(0.2);

      tl.from(titleRef.current, {
        y: 220,
        scaleX: 1.4,
        opacity: 0,
        duration: 0.6,
        ease: "power2.out",
      })
        .from(
          [subtitleRef.current, formRef.current],
          {
            y: 150,
            scaleX: 1.2,
            opacity: 0,
            duration: 0.8,
          },
          "-=0.3"
        )
        .from(appbar, {
          y: -100,
          opacity: 0,
          scaleY: 1.2,
          duration: 0.5,
          ease: "back.out(1.4)",
        })
        .from(
          heroDemoRef.current,
          {
            opacity: 0,
            duration: 0.5,
            y: 200,
            ease: "back.out(1.4)",
          },
          "<"
        );
    }, heroRef);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    const noErrors = Object.values(errors).every((error) => !error);
    const requiredFieldsFilled = ["email"].every(
      (field) => formData[field] !== ""
    );

    setIsFormValid(noErrors && requiredFieldsFilled);
  }, [errors, formData]);

  const regex = {
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "email":
        if (!value) {
          error = "El correo electrónico es requerido.";
        } else if (!regex.email.test(value)) {
          error = "Ingrese un correo electrónico válido.";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const submissionData = {
      name: formData.email,
      email: formData.email,
      message: "",
    };

    if (isFormValid) {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          `${PROD_API_URL}/contact`,
          submissionData
        );
        if (response.status === 200) {
          gsap.to(formRef.current, {
            scale: 1.05,
            duration: 0.2,
            yoyo: true,
            repeat: 1,
            ease: "power2.inOut",
          });

          setFormData({
            name: "",
            email: "",
          });
          setSnackbar({
            open: true,
            message: "¡Mensaje enviado exitosamente!",
            severity: "success",
          });
        }
      } catch (error) {
        // Error shake animation
        gsap.to(formRef.current, {
          x: 10,
          duration: 0.1,
          yoyo: true,
          repeat: 3,
        });

        setSnackbar({
          open: true,
          message: "Error al enviar el mensaje. Por favor, intente nuevamente.",
          severity: "error",
        });
      } finally {
        setIsSubmitting(false);
        setIsFormValid(false);
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsVideoLoaded(true);
    };
    const video = document.getElementById("hero-demo");
    if (video) {
      video.addEventListener("loadeddata", handleLoad);
    }
    return () => {
      if (video) {
        video.removeEventListener("loadeddata", handleLoad);
      }
    };
  }, []);

  return (
    <>
      <Box
        ref={heroRef}
        id="hero"
        sx={{
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            theme.palette.mode === "dark"
              ? "radial-gradient(ellipse 80% 50% at 50% -20%, rgba(132,195,191,0.5), transparent)"
              : "radial-gradient(ellipse 80% 50% at 50% -20%, rgba(132,195,191,0.7), transparent)",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack
            spacing={2}
            sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
          >
            <Typography
              ref={titleRef}
              variant="h1"
              className="hero-title-primitive"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                fontSize: {
                  xs: "clamp(3rem, 10vw, 4rem)",
                  sm: "clamp(4rem, 14vw, 5.5rem)",
                  md: "clamp(4rem, 14vw, 5.5rem)",
                },
                lineHeight: {
                  xs: "1.1",
                  sm: "1.2",
                },

                textAlign: { xs: "center", sm: "left" },
                marginBottom: { xs: 3, sm: 3 },
                paddingX: { xs: 2 },
              }}
            >
              <div>
                <Box
                  sx={{
                    perspective: "1000px",
                    height: "1.5em",
                    width: "100%",
                    marginTop: "1em",
                    verticalAlign: "top",
                    display: "flex",
                    alignItems: "top",
                    justifyContent: "center",
                  }}
                >
                  {phrases.map((word, index) => (
                    <Typography
                      key={index}
                      ref={(el) => (wordRefs.current[index] = el)}
                      component="span"
                      variant="h1"
                      className="animated-gradient-text"
                      sx={{
                        fontSize: "inherit",
                        fontWeight: "700",
                        position: "absolute",
                        top: 0,
                        transformOrigin: "center",
                        backfaceVisibility: "hidden",
                        /* text start ont op */
                        transform: "translateZ(0)",
                        overflow: "visible !important",
                        lineHeight: "1.5",
                        opacity: index === currentIndex ? 1 : 0,
                      }}
                    >
                      <b>{word}</b>
                    </Typography>
                  ))}
                </Box>
                <span className="hero-subtitle-fixed">
                  el acceso a la salud.
                </span>
              </div>
            </Typography>

            <Typography
              id="hero-subtitle"
              ref={subtitleRef}
              sx={{
                textAlign: "center",
                color: "text.secondary",
                width: { sm: "100%", md: "80%" },
                fontSize: { xs: "1.2rem", sm: "1.4rem" },
              }}
            >
              <div>
                Hacemos que <b>más</b> pacientes reciban <b>mejor</b> atención
                médica <b>en menos de un par de minutos.</b>
              </div>
            </Typography>
          </Stack>
          <StyledBox
            ref={heroDemoRef}
            className="vita-hero-demo"
            sx={{ opacity: 1, height: "fit-content" }}
          >
            <video
              id="hero-demo"
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              onLoadedData={() => setIsVideoLoaded(true)}
              style={{
                width: "100%",
                objectPosition: "-1px 0",
                opacity: isVideoLoaded ? 1 : 0,
              }}
            >
              <source
                src="https://usevita.health/vitachat_raw_trimmed.mp4"
                type="video/mp4"
              />
            </video>

            <Skeleton
              variant="rectangular"
              height="100%"
              width="100%"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "inherit",
                opacity: isVideoLoaded ? 0 : 1,
                position: "absolute",
              }}
              animation="wave"
            >
              &nbsp;
            </Skeleton>
          </StyledBox>
        </Container>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
